<template>
  <v-app-bar-nav-icon icon @click="toggleMainMenu">
    <v-icon>menu</v-icon>
  </v-app-bar-nav-icon>
</template>

<script>
export default {
  name: 'MainMenuButton',
  methods: {
    toggleMainMenu() {
      this.$store.commit('toggleMainMenuState');
    },
  },
};
</script>

<style scoped></style>
