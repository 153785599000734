<template>
  <v-app-bar-nav-icon icon @click="goBack">
    <v-icon>arrow_back</v-icon>
  </v-app-bar-nav-icon>
</template>

<script>
export default {
  name: 'BackButton',
  props: ['fallback'],
  data() {
    return {};
  },
  methods: {
    goBack() {
      if (this.$store.state.previousRouteName) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: this.fallback });
      }
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
