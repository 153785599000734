<template>
  <v-container
    :class="[name, alignNormal ? 'align-normal' : null].filter(Boolean)"
    :fill-height="fillHeight || loading"
    :fluid="fluid"
    :pa-0="noContainerPadding"
  >
    <v-progress-linear
      v-show="subtleLoading"
      :indeterminate="true"
      color="primary lighten-2"
      height="4"
      style="position: fixed; top: 0; left: 0; right: 0; margin: 0; z-index: 99"
    ></v-progress-linear>

    <v-app-bar
      v-if="!hideAppBar || $vuetify.breakpoint.mdOnly"
      :class="hideAppBar && $vuetify.breakpoint.mdOnly ? 'rounded-br-lg' : ''"
      :collapse="hideAppBar && $vuetify.breakpoint.mdOnly"
      :color="appBarColor ? appBarColor : 'rgba(250,250,250,1)'"
      :elevate-on-scroll="elevateAppBarOnScroll"
      :extension-height="extensionHeight"
      :flat="!elevateAppBarOnScroll"
      app
      class="main-app-bar"
      fixed
      hide-on-scroll
      light
    >
      <hb-main-menu-button v-if="$vuetify.breakpoint.mdOnly"/>
      <v-fade-transition hide-on-leave>
        <template v-if="showSearch">
          <v-toolbar-title style="width: 100%; overflow: visible">
            <v-text-field
              ref="searchInput"
              v-model="searchPhrase"
              :placeholder="searchTitle"
              class="topbar-search"
              color="grey darken-1"
              dense
              full-width
              hide-details
              outlined
              single-line
              style="font-size: inherit"
              @blur="toggleShowSearch"
            >
              <v-icon slot="append" style="cursor: pointer">search</v-icon>
            </v-text-field>
          </v-toolbar-title>
        </template>
      </v-fade-transition>

      <v-fade-transition hide-on-leave>
        <hb-back-button
          v-if="showBackButton && !showSearch"
          ref="backButton"
          :fallback="backButton && backButton.fallback"
        ></hb-back-button>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <v-toolbar-title
          v-if="!showSearch"
          class="text-h4 app-bar-title"
          style="width: 100%"
        >
          {{ title }}
          <v-slide-x-reverse-transition :hide-on-leave="showSearchActivator">
            <span class="body-1">
              <v-btn
                v-if="!loading && titleButton && $vuetify.breakpoint.mdAndUp"
                class="ml-3 mt-n1"
                depressed
                outlined
                small
                @click="titleButton.callback"
              >
                <v-icon left>{{ titleButton.icon }}</v-icon>
                {{ titleButton.text }}
              </v-btn>
            </span>
          </v-slide-x-reverse-transition>
        </v-toolbar-title>
      </v-fade-transition>

      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mdAndUp && showSearchActivator && !showSearch"
        style="margin-right: 0px"
        @click="toggleShowSearch"
      >
        <v-icon>mdi mdi-magnify</v-icon>
      </v-app-bar-nav-icon>
      <slot name="app-bar-nav-icon"></slot>

      <template v-if="$vuetify.breakpoint.mdAndUp && filters">
        <v-menu v-model="showFilterMenu" :close-on-content-click="false" bottom offset-y>
          <template v-slot:activator="{on,attrs}">
            <v-badge
              :content="Object.keys(filters).filter(key => filters[key].active === true).length"
              :dot="$vuetify.breakpoint.xsOnly"
              :value="fab ? 0 : Object.keys(filters).filter(key => filters[key].active === true).length"
              class="ml-1 font-weight-bold"
              color="red"
              left
              offset-x="20"
              offset-y="20"
              overlap
            >
              <v-btn class="px-3" text v-bind="attrs" v-on="on">
                <v-icon>mdi mdi-filter-outline</v-icon>
                {{ $t('filter') }}
              </v-btn>
            </v-badge>
          </template>
          <v-card class="white" max-width="360" min-width="360">
            <v-card-title class="py-1 subtitle-2 font-weight-bold">
              <div>{{ $t('filter') }}</div>
              <v-spacer></v-spacer>
              <v-btn icon small @click="showFilterMenu = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <slot name="filter-content"></slot>
          </v-card>
        </v-menu>
      </template>

      <template v-if="!!$slots['app-bar-extension']" v-slot:extension>
        <slot name="app-bar-extension"></slot>
      </template>
    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <v-row
        v-if="!loading"
        id="content-holder"
        :class="[fillHeight && 'fill-height'].filter(Boolean)"
        :no-gutters="noGutters"
        :style="{ paddingBottom: bottomPadding + 'px' }"
        style="position: relative"
      >
        <slot></slot>
      </v-row>
    </v-fade-transition>

    <template v-if="!loading">
      <v-app-bar
        v-if="showBottomBar"
        ref="bottomBar"
        :color="$vuetify.breakpoint.smAndDown ? 'secondary' : 'white'"
        :dark="$vuetify.breakpoint.smAndDown"
        :inverted-scroll="bottomBarHideOnScroll"
        :style="{ left: $vuetify.application.left + 'px', right: $vuetify.application.right + 'px' }"
        bottom
        fixed
        style="top: auto; bottom: 0"
      >
        <slot name="bottom-bar">
          <template v-if="$vuetify.breakpoint.smAndDown">
            <hb-main-menu-button/>
            <template v-if="filters">
              <v-badge
                v-if="$isTenantRoute"
                :content="Object.keys(filters).filter(key => filters[key].active === true).length"
                :value="fab ? 0 : Object.keys(filters).filter(key => filters[key].active === true).length"
                class="font-weight-bold"
                color="red"
                offset-x="20"
                offset-y="20"
                overlap
              >
                <v-app-bar-nav-icon @click="showFilterMenu = !showFilterMenu">
                  <v-icon>mdi mdi-filter-outline</v-icon>
                </v-app-bar-nav-icon>
              </v-badge>
            </template>
            <v-app-bar-nav-icon v-if="showSearchActivator"
                                @click="() => (showSearch ? focusSearch() : toggleShowSearch())">
              <v-icon>mdi mdi-magnify</v-icon>
            </v-app-bar-nav-icon>
            <slot name="app-bar-nav-icon"></slot>
          </template>

          <template
            v-if="$vuetify.breakpoint.mdAndUp && !!$slots['bottom-bar-help'] && $vuetify.application.right < 100">
            <v-row no-gutters style="max-width: 60%">
              <v-col cols="12" style="line-height: 1rem">
                <slot name="bottom-bar-help"></slot>
              </v-col>
            </v-row>
          </template>

          <v-spacer></v-spacer>
          <div v-if="actionButtons && Object.keys(actionButtons).length > 0" :style="actionButtonsStyle">
            <template v-for="(button, key) in actionButtons">
              <!-- sm and down buttons, because of the different transition -->
              <v-fab-transition v-if="$vuetify.breakpoint.smAndDown" :key="key">
                <v-btn
                  v-if="buttonWaitDurationIsOver && !loading"
                  :color="button.primary ? 'primary' : ''"
                  :disabled="button.disabled"
                  :large="button.primary"
                  :loading="button.loading"
                  class="mx-1"
                  fab
                  @click="button.callback"
                >
                  <v-icon>{{ button.icon }}</v-icon>
                </v-btn>
              </v-fab-transition>
              <!-- md and up buttons, because of the different transition -->
              <v-slide-x-transition v-if="$vuetify.breakpoint.mdAndUp" :key="key">
                <v-btn
                  v-if="buttonWaitDurationIsOver && !loading"
                  :color="button.primary ? 'primary' : ''"
                  :disabled="button.disabled"
                  :loading="button.loading"
                  class="mx-1"
                  depressed
                  @click="button.callback"
                >
                  <v-icon left>{{ button.icon }}</v-icon>
                  <span>{{ button.text }}</span>
                </v-btn>
              </v-slide-x-transition>
            </template>
          </div>
        </slot>
      </v-app-bar>

      <template v-if="$vuetify.breakpoint.smAndDown && filters">
        <v-bottom-sheet v-model="showFilterMenu" hide-overlay>
          <v-card class="white">
            <v-card-title class="py-1 subtitle-2 font-weight-bold">
              <div>{{ $t('filter') }}</div>
              <v-spacer></v-spacer>
              <v-btn icon small @click="showFilterMenu = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider class="mb-4"></v-divider>
            <slot name="filter-content"></slot>
          </v-card>
        </v-bottom-sheet>
      </template>

    </template>
  </v-container>
</template>

<script>
import isEqual from 'lodash/isEqual';

export default {
  props: {
    name: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    fluid: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: undefined,
    },
    subtleLoading: {
      type: Boolean,
      default: undefined,
    },
    backButton: {
      type: Object,
      default: undefined,
    },
    extensionHeight: {
      type: Number,
      default: null,
    },
    searchTitle: {
      type: String,
      default: undefined,
    },
    noGutters: {
      type: Boolean,
      default: undefined,
    },
    noContainerPadding: {
      type: Boolean,
      default: undefined,
    },
    fillHeight: {
      type: Boolean,
      default: undefined,
    },
    alignNormal: {
      type: Boolean,
      default: undefined,
    },
    actionButtons: {
      type: Object,
      default: () => {
      },
    },
    titleButton: {
      type: [Object, Boolean],
      default: false,
    },
    bottomBarHideOnScroll: {
      type: Boolean,
      default: false,
    },
    hideAppBar: {
      type: Boolean,
      default: false,
    },
    appBarColor: {
      type: String,
      default: undefined,
    },
    filters: {
      type: Object,
      default: null,
    },
    elevateAppBarOnScroll: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      searchPhrase: '',
      showNotificationDrawer: false,
      fab: false,
      users: [],
      buttonWaitDurationIsOver: false,
      showSearch: false,
      bottomPadding: 0,
      showFilterMenu: false,
    };
  },
  computed: {
    showBottomBar() {
      return this.$vuetify.breakpoint.smAndDown || (this.actionButtons && Object.keys(this.actionButtons).length > 0);
    },
    showBackButton() {
      return this.backButton != null && this.backButton.show;
    },
    showSearchActivator() {
      return this.searchTitle != null;
    },
    actionButtonsStyle() {
      const styles = {};
      if (this.$vuetify.breakpoint.smAndDown) {
        styles.position = 'absolute';
        styles.top = '-32px';
        styles.right = '16px';
        styles.left = '16px';
        styles.height = '0';
        styles.textAlign = 'right';
      }
      return styles;
    },
  },
  methods: {
    goBack() {
      if (this.$refs.backButton) {
        this.$refs.backButton.goBack();
      }
    },
    focusSearch() {
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    toggleShowSearch() {
      if (this.searchPhrase.length > 0 && this.showSearch) return;
      this.showSearch = !this.showSearch;
      if (this.showSearch) {
        this.focusSearch();
      }
    },
    clearSearch() {
      this.searchPhrase = '';
      if (this.showSearch) {
        this.toggleShowSearch();
      }
    },
    setSearchPhrase(phrase) {
      this.searchPhrase = phrase;
      if (phrase && phrase.length > 0) {
        this.showSearch = true;
      } else {
        this.showSearch = false;
      }

    },

  },
  watch: {
    showBottomBar(val) {
      if (val) {
        this.$nextTick(() => {
          if (this.$refs.bottomBar) {
            this.bottomPadding = this.$refs.bottomBar.$el.clientHeight;
          } else {
            this.bottomPadding = 0;
          }
        });
      } else {
        this.bottomPadding = 0;
      }
    },
    searchPhrase(to) {
      this.$emit('handle-search', to);
    },
    title(to) {
      document.title = to !== undefined ? `${to} - ${this.$t('title')}` : this.$t('title');
    },
    actionButtons(to, from) {
      if (!this.loading && isEqual(Object.keys(to), Object.keys(from))) {
        return;
      }
      this.buttonWaitDurationIsOver = false;
      const checkForLoadingState = () => {
        setTimeout(() => {
          if (this.loading) {
            checkForLoadingState();
          } else {
            this.buttonWaitDurationIsOver = true;
          }
        }, 500);
      };
      checkForLoadingState();
    },
    loading(val) {
      if (!val && !this.buttonWaitDurationIsOver) {
        setTimeout(() => {
          this.buttonWaitDurationIsOver = true;
        }, 500);
      }
    },
  },
  updated() {
    if (this.$refs.bottomBar) {
      this.bottomPadding = this.$refs.bottomBar.$el.clientHeight;
    }
  },
  mounted() {
    document.title = this.title ? `${this.title} - ${this.$t('title')}` : this.$t('title');
    if (!this.loading) this.buttonWaitDurationIsOver = true;
  },
};
</script>

<style lang="scss">
.topbar-search {
  .v-input__slot {
    &:after {
      display: none;
    }
  }
}

.v-application--is-ltr {
  .main-app-bar {
    .v-toolbar__content {
      padding: 4px 12px;
    }

    .v-toolbar__content > .v-btn.v-btn--icon:first-child {
      margin-left: -8px;
    }

    .app-bar-title {
      padding-left: 0 !important;
    }
  }
}

.container.fill-height.align-normal {
  align-items: normal;
}

@media only screen and (min-width: 960px) {
  .main-app-bar {
    .v-toolbar__content {
      padding: 4px 16px 4px 12px;
    }

    .app-bar-title {
      padding-left: 10px !important;
    }
  }
}
</style>
