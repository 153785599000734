<template>
  <v-app id='app' light>
    <confirm-dialog ref='confirm'/>
    <template v-if='showApplication'>
      <template v-if='loadingOverlay'>
        <v-fade-transition>
          <div class='loading-overlay white'>
            <v-row align='center' class='fill-height' justify='center'>
              <v-col class='text-center' cols='4'>
                <hb-loading-indicator :align-middle='true'/>
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
      </template>

      <v-snackbar v-model='snackbar' :color='notification.color' :timeout='notification.timeOut' multi-line top>
        {{ notification.text }}
        <template v-slot:action>
          <template v-if='notification.showButton'>
            <v-btn
              outlined
              small
              text
              @click='
                () => {
                  notification.callback();
                  snackbar = false;
                }
              '
            >
              {{ notification.buttonText }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn icon @click='snackbar = false'>
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </template>
      </v-snackbar>

      <v-navigation-drawer
        v-if='shouldMenuBeAvailable && !hideMenu'
        v-model='menuOpenState'
        :bottom='$vuetify.breakpoint.smAndDown'
        :mini-variant='miniMenu && $vuetify.breakpoint.lgAndUp'
        app
        color='white'
        fixed
        light
        width='300'
      >
        <v-responsive>
          <v-img
            :height="!miniMenu && $vuetify.breakpoint.lgAndUp ? '120px' : $vuetify.breakpoint.mdAndDown ? '100px' : '60px'"
            src="/img/wood.jpg">
            <v-row align="center" class="fill-height" no-gutters>
              <v-img class="mr-n6"
                     contain
                     max-height="100%"
                     max-width="100%"
                     src="/img/logo.svg"
                     @click.stop='toggleMiniMenu'></v-img>
              <v-btn v-if='!miniMenu' class="mr-1" icon small @click.stop='toggleMiniMenu'>
                <v-icon v-if='$vuetify.breakpoint.mdAndUp'>chevron_left</v-icon>
                <v-icon v-if='$vuetify.breakpoint.smAndDown'>close</v-icon>
              </v-btn>
            </v-row>
          </v-img>
        </v-responsive>

        <v-list :class="$vuetify.breakpoint.smAndDown ? 'white darken-1' : ''"
                :dense='$vuetify.breakpoint.mdAndDown'
                class='pt-0 main-menu'>

          <template v-if='$userInfo && $userInfo.tenants.length > 1'>
            <TenantSelect :miniMenu='miniMenu'/>
            <v-divider></v-divider>
          </template>

          <template v-if="$hasMatchingTenantRoles(['tenant_basic', 'tenant_admin'])">
            <v-subheader v-if='!miniMenu'>{{ $t('menu_subheaders.production_pages') }}</v-subheader>
            <PageLink
              v-for='(page, index) in tenantProductionPages'
              :key="index + 'primary'"
              :mini-menu="miniMenu"
              :page='page'
              @click='closeMenuIfOnSamePage(page.link)'
            ></PageLink>
          </template>

          <template v-if="$hasMatchingTenantRoles(['tenant_office', 'tenant_admin'])">
            <v-divider></v-divider>
            <v-subheader v-if='!miniMenu'>{{ $t('menu_subheaders.office_pages') }}</v-subheader>
            <PageLink
              v-for='(page, index) in tenantOfficePages'
              :key="index + 'secondary'"
              :mini-menu="miniMenu"
              :page='page'
              @click='closeMenuIfOnSamePage(page.link)'
            ></PageLink>
          </template>

          <template v-if="$hasMatchingTenantRoles(['tenant_admin'])">
            <v-divider></v-divider>
            <v-subheader v-if='!miniMenu'>{{ $t('menu_subheaders.organization_admin') }}</v-subheader>
            <PageLink
              v-for='(page, index) in tenantAdminPages'
              :key="index + 'tadmin'"
              :mini-menu="miniMenu"
              :page='page'
              @click='closeMenuIfOnSamePage(page.link)'
            ></PageLink>
          </template>

          <template v-if="$hasMatchingRoles(['ADMIN'])">
            <v-divider></v-divider>
            <v-subheader v-if='!miniMenu'>{{ $t('menu_subheaders.application_admin') }}</v-subheader>
            <PageLink
              v-for='(page, index) in adminPages'
              :key="index + 'admin'"
              :mini-menu="miniMenu"
              :page='page'
              @click='closeMenuIfOnSamePage(page.link)'
            ></PageLink>
            <v-divider></v-divider>
          </template>


          <v-subheader v-if='!miniMenu'>{{ $t('user_profile.user_settings') }}</v-subheader>

          <PageLink
            :mini-menu="miniMenu"
            :page="{ title: $t('user_profile.title'), icon: 'person', link: 'userprofile' }"
            @click="closeMenuIfOnSamePage('userprofile')"
          ></PageLink>
          <PageLink
            :mini-menu="miniMenu"
            :page="{ title: $t('login.logout'), icon: 'logout', link: 'logout' }"
            @click='logout'
          ></PageLink>

        </v-list>
      </v-navigation-drawer>

      <v-main>
        <router-view/>
      </v-main>
    </template>
    <template v-else>
      <no-tenants-view />
    </template>
  </v-app>
</template>

<script>
import TenantSelect from './components/TenantSelect.vue';
import TenantService from './service/tenant.service';
import UiEventBus from '@/UiEventBus';
import PageLink from '@/components/App/PageLink';
import dayjs from 'dayjs';
import 'dayjs/locale/fi';
import 'dayjs/locale/en';
import i18n from "@/i18n";

import * as Sentry from '@sentry/vue';
import ConfirmDialog from '@/components/ConfirmDialog';

import UserService from '@/service/user.service';
import TranslationService from '@/service/translation.service';
import NoTenantsView from "@/views/NoTenantsView";
import '/node_modules/flag-icons/css/flag-icons.min.css';

const advancedFormat = require('dayjs/plugin/advancedFormat');
const isoWeek = require('dayjs/plugin/isoWeek');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const calendar = require('dayjs/plugin/calendar');
const duration = require('dayjs/plugin/duration');
const isBetween = require('dayjs/plugin/isBetween');
const objectSupport = require('dayjs/plugin/objectSupport');
const isToday = require('dayjs/plugin/isToday');

dayjs.extend(customParseFormat);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
dayjs.extend(calendar);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(objectSupport);
dayjs.extend(isToday);
dayjs.locale(i18n.locale);

export default {
  components: {NoTenantsView, ConfirmDialog, PageLink, TenantSelect},
  props: {
    hideMenu: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      notification: {
        color: '',
        text: '',
        buttonText: '',
        showButton: false,
        timeOut: -1,
        callback: () => {
        },
      },
      loadingUserLanguage: false,
      snackbar: false,
      miniMenu: false,
      bottomNavSelected: '',
      showBottomNav: true,
      menuOpenState: false,
      pages: [],
    };
  },
  methods: {
    getPageLink(page) {
      const link = {
        name: page.link,
      };

      if ('params' in page) {
        link.params = page.params();
      }
      return link;
    },
    logout() {
      UiEventBus.$emit('userLoggedOut');
    },
    goToLink(name) {
      this.$router.push({name: name});
    },
    toggleMiniMenu() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.miniMenu = !this.miniMenu;
      } else {
        this.$store.commit('toggleMainMenuState');
      }
    },
    closeMenuIfOnSamePage(link) {
      if (this.$vuetify.breakpoint.mdAndDown && this.$route.name === link) {
        this.menuOpenState = false;
      }
    },
    handleSelectedBottomNavItem(name) {
      const isPagePrimary = this.pages.findIndex(p => p.primary && p.link === name) > -1;
      if (isPagePrimary) {
        this.bottomNavSelected = name;
        this.showBottomNav = true;
      } else {
        this.showBottomNav = false;
      }
    },
    async changeUserLanguage(language) {
      this.loadingUserLanguage = true;
      let done = false;
      try {
        await UserService.setUserLanguage(language);
        done = true;
      } catch (e) {
        this.$handleApiError(e, this.$t('errors.error_language_change_failed'));
      }
      this.loadingUserLanguage = false;
      if (done) window.location.reload();

    },
  },
  watch: {
    $isLoggedIn(newValue, oldValue) {
      console.log('isLoggedInChange', newValue, oldValue);
      if (newValue === true) {
        UiEventBus.$emit('loginSuccess');
      }
    },
    loadingOverlay(to) {
      if (to) {
        setTimeout(() => {
          this.$store.commit('setLoadingOverlay', false);
        }, 2000);
      }
    },
    notification() {
      this.snackbar = true;
    },
    menuOpenComputed(to) {
      this.menuOpenState = to;
    },
    menuOpenState(to) {
      if (to !== this.$store.state.mainMenuOpen) {
        this.$store.commit('toggleMainMenuState');
      }
    },
    $route(to, from) {
      if (from && from.name) this.$store.commit('setPreviousRouteName', from.name);
      this.handleSelectedBottomNavItem(to.name);
    },
    currentRouteName(val) {
      if (!this.$isLoggedIn && val !== 'login') {
        this.$router.push({name: 'login'});
      }
    },
  },
  computed: {
    tenantAdminPages() {
      return [
        {
          title: this.$t('tenants.management_title'),
          icon: 'settings',
          link: 'tenant_settings',
          params: () => ({tenantId: TenantService.tenant}),
          tenantRoles: ['tenant_admin'],
          primary: false,
        },
        {
          title: this.$t('tenants.organization_users'),
          icon: 'people',
          link: 'tenant_users',
          params: () => ({tenantId: TenantService.tenant}),
          tenantRoles: ['tenant_admin'],
          primary: false,
        },
        {
          title: this.$tc('production_lines.title', 1),
          icon: 'mdi mdi-factory',
          link: 'tenant_production_lines',
          params: () => ({tenantId: TenantService.tenant}),
          tenantRoles: ['tenant_admin'],
          primary: false,
        },
      ];
    },
    tenantOfficePages() {
      return [
        {
          title: this.$t('production_timeline.title'),
          icon: 'mdi mdi-store-24-hour',
          iconSize: '20px',
          link: 'tenant_production_timeline',
          params: () => ({tenantId: TenantService.tenant}),
          roles: ['tenant_admin', 'tenant_office'],
          primary: true,
        },
        {
          title: this.$tc('project.title', 2),
          icon: 'mdi mdi-home-group',
          iconSize: '20px',
          link: 'tenant_projects',
          params: () => ({tenantId: TenantService.tenant}),
          roles: ['tenant_admin', 'tenant_office'],
          primary: true,
        },
        {
          title: this.$tc('warehousereceipt.title', 2),
          icon: 'mdi mdi-warehouse',
          iconSize: '20px',
          link: 'tenant_warehouse_receipts',
          params: () => ({tenantId: TenantService.tenant}),
          roles: ['tenant_admin', 'tenant_office'],
          primary: true,
        },
        {
          title: this.$tc('item.title', 2),
          icon: 'mdi mdi-clipboard-list-outline',
          iconSize: '20px',
          link: 'tenant_items',
          params: () => ({tenantId: TenantService.tenant}),
          roles: ['tenant_admin', 'tenant_office'],
          primary: true,
        },
      ]
    },
    tenantProductionPages() {
      return [
        {
          title: this.$t('production.title'),
          icon: 'mdi mdi-store-24-hour',
          iconSize: '20px',
          link: 'tenant_production',
          params: () => ({tenantId: TenantService.tenant}),
          roles: ['tenant_basic', 'tenant_admin'],
          primary: true,
        },
      ]
    },
    adminPages() {
      return [
        {
          title: this.$t('user_management.title'),
          icon: 'people',
          link: 'users',
          roles: ['ADMIN'],
          primary: false,
        },
        {
          title: this.$t('tenants.title'),
          icon: 'business',
          link: 'tenants',
          roles: ['ADMIN'],
          primary: false,
        }
      ];
    },
    languages() {
      return TranslationService.languages.filter(l => ['fi', 'en'].includes(l)).map(l => {
        return {
          text: this.$t('translations.select_languages.' + l),
          value: l,
          icon: l === 'en' ? 'gb' : l
        };
      });
    },
    userLanguage() {
      return TranslationService.language;
    },
    showApplication() {
      return !this.$isLoggedIn || this.$isAdmin || (this.$userInfo && this.$userInfo.tenants.length > 0);
    },
    loadingOverlay() {
      return this.$store.state.loadingOverlay;
    },
    shouldMenuBeAvailable() {
      return this.$isLoggedIn;
    },
    menuOpenComputed() {
      let menuState = true;
      if (this.$vuetify.breakpoint.mdAndDown) {
        menuState = this.$store.state.mainMenuOpen;
      }
      return menuState;
    },
    filteredPrimaryPages() {
      let pages = this.pages.filter(p => p.primary);

      if (this.$tenant) {
        pages = [...pages, ...this.tenantOfficePages.filter(tp => tp.primary)];
      }
      return pages;
    },
    filteredSecondaryPages() {
      let pages = this.pages.filter(p => !p.primary);

      if (this.$tenant) {
        pages = [...pages, ...this.tenantOfficePages.filter(tp => !tp.primary)];
      }
      return pages;
    },
    currentRouteName() {
      return this.$route.name;
    },

  },
  created() {
    UiEventBus.$on('showNotification', payload => {
      this.notification = {
        color: payload.color,
        text: payload.message,
        timeOut: payload.timeOut !== undefined ? payload.timeOut : 4000,
        showButton: payload.showButton !== undefined ? payload.showButton : false,
        buttonText: payload.buttonText !== undefined ? payload.buttonText : '',
        callback: payload.showButton && payload.callback ? payload.callback : () => {
        },
      };
    });
    // If application is opened from link, and some other tenant id has been saved in tenant service
    // This sets the one from URL
    if ('tenantId' in this.$router.currentRoute.params) {
      TenantService.setTenant(this.$router.currentRoute.params.tenantId);
    }
  },

  async mounted() {
    if (this.$userInfo) {
      if (Sentry) {
        Sentry.setUser({email: this.$userInfo.email});
      }
    }
    if (this.$vuetify.breakpoint.lgAndUp) this.menuOpenState = true;
    this.handleSelectedBottomNavItem(this.$route.name);
    this.$root.$confirm = this.$refs.confirm.open;
    await this.$store.commit('setUserInfo', this.$userInfo);
  },
};
</script>

<style lang='scss'>
@import './sass/commonstyles.scss';

@media screen and (max-width: 959px) {
  .main-menu {
    max-height: calc(100% - 100px);
    overflow: auto;
  }
}
</style>
