<template>
  <v-list-item
    v-if="userHasRequiredRoles"
    :to="pageLink"
    active-class="grey lighten-4 active-menu-item"
    @click="click">
    <v-list-item-action class="my-1 mr-7">
      <v-icon v-if="!miniMenu" size="28" class="page-icon">{{ page.icon }}</v-icon>
      <v-tooltip v-else right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="page-icon" v-bind="attrs" v-on="on">{{ page.icon }}</v-icon>
        </template>
        <span>{{ page.title }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title class="page-title">{{ page.title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: ['page', 'miniMenu'],
  name: 'PageLink',
  methods: {
    click() {
      this.$emit('click');
    },
  },
  computed: {
    userHasRequiredRoles() {
      if (this.page.tenantRoles && this.page.tenantRoles.length > 0) {
        return this.$hasMatchingTenantRoles(this.page.tenantRoles);
      } else if (this.page.roles && this.page.roles.length > 0) {
        return this.$hasMatchingRoles(this.page.roles);
      }
      return true;
    },
    pageLink() {
      const page = this.page;
      const link = {
        name: page.link,
      };

      if ('params' in page) {
        link.params = page.params();
      }
      return link;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  font-weight: 500 !important;
  caret-color: var(--v-primary-lighten2) !important;
}

.page-icon {
  color: var(--v-accent-lighten4) !important;
  //caret-color: var(--v-accent-lighten4) !important;
}

.active-menu-item {
  .page-title {
    font-weight: 600 !important;
    color: var(--v-primary-base) !important;
    caret-color: var(--v-primary-base) !important;
  }
  .page-icon {
    color: var(--v-accent-base) !important;
    caret-color: var(--v-accent-base) !important;
  }

  &:before {
    opacity: 0;
  }
}
</style>
